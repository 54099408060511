import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import imgF1 from "../../assets/image/svg/l6-feature-icon1.svg";
import imgF2 from "../../assets/image/svg/l6-feature-icon2.svg";
import imgF3 from "../../assets/image/svg/l6-feature-icon3.svg";
import imgF4 from "../../assets/image/svg/l6-feature-icon4.svg";
import imgF5 from "../../assets/image/svg/l6-feature-icon5.svg";
import imgF6 from "../../assets/image/svg/l6-feature-icon6.svg";
import section3Artwork from "../../assets/image/Section3_artwork.jpg";
import styled from "styled-components";
import { Link } from "gatsby";

const WidgetIconDiv = p => (
  <div
    {...p}
    className="widget-icon text-center gr-text-4 text-green mr-sm-9 mb-7 mb-lg-0"
  />
);
const WidgetTextDiv = p => (
  <div
    {...p}
    className="content text-center text-sm-left px-10 px-sm-0 pr-xl-6"
  />
);

const Artwork = styled.div`
  margin-top: -75px;
  margin-left: -150px;
  z-index: -1;
`;

const Solution = styled.p`
  margin-top: -20px;
`;

const Unit = () => {
  return (
    <>
      {/* <!-- Features Area --> */}
      <div className="feature-section pt-24 pt-lg-20 pb-24 text-dark">
        <Container>
          <Row className="justify-content-center">
            <Col lg="7">
              <h1 className="text-center gr-text-5 text-dark-green">
                {/* <div>Shoppable Media</div>{" "}
                  <div className="gr-text-4 gr-opacity-8">meets</div>{" "}
                  <div>Performance Marketing</div> */}
                Experience Unrivaled Confidence Through Data-Driven
                Decision-Making with Pear Commerce
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-center mt-8 mb-20">
            <Col lg="11">
              <p className="text-center gr-text-7 px-12">
                Pear's shoppable ads and store locators allow consumers to buy
                your products from nearly any retailer, so you can meet them
                where they shop. Pear offers all the capabilities you'd expect,
                but has evolved this form of shoppable media to empower CPG
                brands with performance marketing capabilities previously
                exclusive to direct-to-consumer brands.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center mt-12">
            <Col lg="6" className="mb-12 px-12">
              <h1 className="gr-text-5 mb-4 text-dark-green">
                <div>Get Clear Insight into Return on Digital Ad Spend</div>{" "}
              </h1>
              <p className="gr-text-7 ">
                Access far more detailed sales data than Pear's competitors.
                With reporting on metrics across the funnel, from page loads to
                each transaction, you can easily prove sales and quantify return
                on investment.
              </p>
            </Col>
            <Col lg="6" className="mb-12 px-12">
              <h1 className="gr-text-5 mb-4 text-dark-green">
                <div>Boost Sales with Regional Retail Partners</div>{" "}
              </h1>
              <p className="gr-text-7 ">
                Almost half of retail sales take place at regional retailers.
                Pear is the only tool working with regional retailers to help
                you get your share of that revenue. Real-time in-stock
                intelligence eliminates out-of-stock and broken links that cause
                missed sales.
              </p>
            </Col>
            <Col lg="6" className="mb-xs-12 px-12 z-index-2">
              <h1 className="gr-text-5 mb-4 text-dark-green">
                <div>Optimize Campaigns with Speed and Clarity</div>{" "}
              </h1>
              <p className="gr-text-7 ">
                Don't wait months to find out how a campaign is performing.
                Pear's easy-to-use interactive dashboard reports at the purchase
                and conversion level. Unlike other solutions, Pear is not
                limited to data at the retailer level or simple daily averages.
              </p>
            </Col>
            <Col lg="6" className="mb-12 px-12">
              <h1 className="gr-text-5 mb-4 text-dark-green">
                <div>Gain Audience Segmentation Capabilities</div>{" "}
              </h1>
              <p className="gr-text-7 ">
                Analyze full-funnel metrics and raw session-level data by UTM,
                geography, retailer, device, and more to build lookalike
                audiences of those who have already purchased. Re-market to
                shoppers who didn't purchase.
              </p>
            </Col>
            <Col xs="5" lg="6">
              <Artwork className="position-relative z-index-n1">
                <img className="w-130" src={section3Artwork} alt="" />
              </Artwork>
            </Col>
            <Col lg="6" className="mb-12 px-12">
              <h1 className="gr-text-5 mb-4 text-dark-green">
                <div>Get Started Quickly and Easily</div>{" "}
              </h1>
              <p className="gr-text-7 ">
                Pear's industry-leading technology automates the process, so you
                can start collecting insights in days instead of weeks. You'll
                get full visibility using the Pear dashboard.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-md-center text-center mb-10">
            <Col lg="11">
              <Solution className="gr-text-7 px-12">
                By unlocking detailed sales data from the top of the funnel all
                the way down to the transaction level, Pear allows you to
                discover more insights into consumer behavior than previously
                possible. When you use those insights to quickly optimize
                campaigns and capture more sales, you break down the barriers to
                business growth.
              </Solution>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col className="text-center" xs="4">
              <Link to="#CPGContact" className="btn btn-blue rounded-2">
                Schedule A Demo
              </Link>
            </Col>
            <Col className="text-center" xs="4">
              <Link to="/cpg" className="btn btn-blue rounded-2">
                How it Works
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Unit;
