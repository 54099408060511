import React from "react";
// import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import imgHW from "../../assets/image/svg/l6-hero-wave.svg";
import imgHWD from "../../assets/image/svg/l6-hero-wave-dark.svg";
import imgS1 from "../../assets/image/l6/png/l6-hero-shape1.png";
import imgS2 from "../../assets/image/l6/png/l6-hero-shape2.png";
import imgS3 from "../../assets/image/l6/png/l6-hero-shape3.png";
//import imgHero from "../../assets/image/pear-dashboard.png";
import section2Graphic from "../../assets/image/Section2_Graphic.png";

import { Select } from "../../components/Core";
import styled from "styled-components";

const GraphicImg = styled.img`
  margin-top: -65px;
  margin-left: -20px;
`;

const Unit = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="hero-area-03 bg-default pt-20 pb-12 position-relative">
        <Container className="bg-default">
          <Row className="px-xs-14">
            <Col className="ml-lg-n16 px-lg-8" lg="8">
              <div className="align-items-left">
                <h1 className="gr-text-5 text-dark-green mb-10">
                  Stop Wasting Time and Money on Ineffective Retail Ad
                  Strategies
                </h1>
                <div className="text-dark">
                  <p className="gr-text-7 text-dark">
                    These common problems can lead to wasted marketing dollars
                    on unsuccessful digital ads:
                  </p>
                  <ul className="gr-text-7 text-dark">
                    <li>No direct path to purchase</li>
                    <li>
                      No real-time campaign performance data down to the
                      individual purchase level
                    </li>
                    <li>
                      No real-time retail inventory data, so consumers click to
                      dead-end out-of-stock pages
                    </li>
                    <li>Inability to retarget and optimize campaigns</li>
                    <li>
                      No way to send consumers to regional retailers where
                      nearly half of purchases take place
                    </li>
                  </ul>
                  <p className="gr-text-7 text-dark">
                    In the past, CPG brands selling into retail have been unable
                    to access the kind of sales data that empowers
                    direct-to-consumer brands to create effective digital ad
                    campaigns. Imagine if you could solve these problems to
                    empower your marketing team with the same capabilities that
                    direct-to-consumer brands enjoy.
                  </p>
                  <p className="gr-text-7 text-dark">
                    With Pear Commerce, now you can.
                  </p>
                </div>
              </div>
            </Col>
            <Col className="d-none d-lg-block" lg="4">
              <div
                className="hero-img position-relative z-index-1"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <GraphicImg className="w-200" src={section2Graphic} alt="" />
                <div
                  className="pattern-sub-img gr-abs-tr-custom-2"
                  data-aos="fade-up-right"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                ></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Unit;
