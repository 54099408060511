import React from "react";
// import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import imgHW from "../../assets/image/svg/l6-hero-wave.svg";
import imgHWD from "../../assets/image/svg/l6-hero-wave-dark.svg";
import imgS1 from "../../assets/image/l6/png/l6-hero-shape1.png";
import imgS2 from "../../assets/image/l6/png/l6-hero-shape2.png";
import imgS3 from "../../assets/image/l6/png/l6-hero-shape3.png";
//import imgHero from "../../assets/image/pear-dashboard.png";
import imgHero from "../../assets/image/pear-section-1-hero.png";

import { Select } from "../../components/Core";

import imgHB from "../../assets/image/job-site/png/hero-img-big.png";
import imgDot from "../../assets/image/job-site/png/hero-dot-pattern.png";
import imgSO from "../../assets/image/job-site/png/hero-oval-shape.png";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <div className="hero-area-03 bg-gradient-4 pt-16 pt-md-12 pt-lg-16 pb-8 position-relative text-white">
        <div
          className="shape-1 gr-abs-tl"
          data-aos="fade-down-right"
          data-aos-duration="500"
          data-aos-delay="500"
        >
          <img src={imgS1} alt="" />
        </div>
        <div
          className="shape-2 gr-abs-tl"
          data-aos="fade-down-right"
          data-aos-duration="800"
          data-aos-delay="800"
        >
          <img src={imgS2} alt="" />
        </div>
        <div
          className="shape-3 gr-abs-tl"
          data-aos="fade-down-right"
          data-aos-duration="1100"
          data-aos-delay="1100"
        >
          <img src={imgS3} alt="" />
        </div>
        <Container
          fluid
          className="justify-content-md-center"
          css={`
            max-width: 1600px;
          `}
        >
          <Row className="align-items-center justify-content-center">
            <Col xs="7" lg="5" xl="6" className="d-none d-lg-inline pl-lg-20">
              <div
                className="hero-img position-relative z-index-1"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <img className="w-110" src={imgHero} alt="" />
                <div
                  className="pattern-sub-img gr-abs-tr-custom-2"
                  data-aos="fade-up-right"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                ></div>
              </div>
            </Col>
            <Col
              xs="11"
              sm="10"
              md="9"
              lg="7"
              xl="6"
              className="mt-16 mt-lg-10 mt-xl-12 gr-z-index-99"
            >
              <div className="hero-content text-center justify-content-center text-lg-left justify-content-lg-left pr-4">
                <br />
                <br />
                <h1 className="gr-text-4 text-white mb-8">
                  Uncover the Clarity to Optimize{" "}
                  <br className="d-xs-none d-sm-inline d-md-inline d-lg-none d-xl-inline" />
                  Your CPG's Retail Advertising Strategy
                </h1>
                <Row className="text-center justify-content-center text-lg-left justify-content-lg-left">
                  <Col lg="9" className="px-lg-6 px-12">
                    <div className="gr-text-6">
                      Confidently evaluate and boost the success of your
                      e-commerce retail marketing efforts with Pear Commerce.
                    </div>
                  </Col>
                  <Col xs="12" lg="3"></Col>
                </Row>
                <div className="hero-btns d-flex justify-content-center mt-11 gr-z-index-99 pr-lg-20">
                  <a href="#CPGContact">
                    <button className="text-white with-icon mb-6 mb-md-0 mr-md-7 btn-green btn gr-z-index-99">
                      Schedule a Demo
                      <i className="fas fa-arrow-right gr-text-11"></i>
                    </button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="">
            <Col sm="10" className="mt-12 mt-lg-16 ">
              <br />
              <br />
            </Col>
          </Row>
        </Container>
        <div className="wave-shape gr-z-index-1">
          <img src={imgHW} alt="" className="w-100 light-shape default-shape" />
          <img src={imgHWD} alt="" className="w-100 dark-shape" />
        </div>
      </div>
    </>
  );
};

export default Hero;
