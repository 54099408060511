import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import imgF1 from "../../assets/image/svg/l6-feature-icon1.svg";
import imgF2 from "../../assets/image/svg/l6-feature-icon2.svg";
import imgF3 from "../../assets/image/svg/l6-feature-icon3.svg";
import imgF4 from "../../assets/image/svg/l6-feature-icon4.svg";
import imgF5 from "../../assets/image/svg/l6-feature-icon5.svg";
import iconClock from "../../assets/image/clockgraph-icon-removebg-preview.png";
import iconComputer from "../../assets/image/computer-removebg-preview.png";
import iconDollarSign from "../../assets/image/dollar-sign-removebg-preview.png";
import iconClipboard from "../../assets/image/clipboard-icon-removebg-preview.png";

import section3Artwork from "../../assets/image/Section3_artwork.jpg";
import styled from "styled-components";
import { Link } from "gatsby";

const WidgetIconDiv = p => (
  <div
    {...p}
    className="widget-icon text-center gr-text-4 text-green mr-sm-9 mb-7 mb-lg-0"
  />
);
const WidgetTextDiv = p => (
  <div
    {...p}
    className="content text-center text-sm-left px-10 px-sm-0 pr-xl-6"
  />
);

const Artwork = styled.div`
  margin-top: -75px;
  margin-left: -150px;
  z-index: -1;
`;

const Solution = styled.p`
  margin-top: -15px;
`;

const Icon = styled.img`
  width: 150px;
`;

const Unit = () => {
  return (
    <>
      {/* <!-- Features Area --> */}
      <div className="feature-section py-24 bg-gradient-6">
        <Container className="bg-blue rounded-xl text-light">
          <Row className="justify-content-center">
            <Col lg="6" className="px-14 py-12">
              <Row>
                <h1 className="text-center gr-text-5 text-light mb-6  mr-n6">
                  <div>What Makes Pear Commerce Different?</div>
                </h1>
                <p className="text-light">
                  You know you need retail sales, but driving shoppers to
                  retailer websites to make a purchase means you lose all
                  insight into how well your campaigns are performing. If you
                  feel like you don't know how to spend your marketing dollars
                  efficiently, you aren't alone.
                </p>
                <p className="text-light">
                  Without detailed information about each digital sale all the
                  way down to the moment of purchase, you can't justify ad spend
                  and quickly adjust campaigns for better results. That weakens
                  your entire digital marketing strategy.
                </p>
                <p className="text-light">
                  That's why Pear was created by CPG professionals and seasoned
                  technology experts to address your unique, real-world
                  challenges. With over a decade of experience in digital sales
                  and marketing, we understand all facets of the challenges you
                  face. We've already tracked thousands of purchases and
                  shopping sessions in real-time to perfect our solution.
                </p>
                <Link to="#CPGContact" className="mt-12 btn btn-blue rounded-2">
                  Schedule A Demo
                </Link>
              </Row>
            </Col>
            <Col lg="6" className="pr-14 py-12">
              <div className="feature-widget d-sm-flex my-4">
                <WidgetIconDiv>
                  <Icon className="" src={iconClock} alt="/" />
                </WidgetIconDiv>
                <WidgetTextDiv>
                  <p className="text-light mt-12 gr-text-7">
                    Up-to-the-minute detailed sales data
                  </p>
                </WidgetTextDiv>
              </div>
              <div className="feature-widget d-sm-flex my-4">
                <WidgetIconDiv>
                  <Icon className="" src={iconComputer} alt="/" />
                </WidgetIconDiv>
                <WidgetTextDiv>
                  <p className="text-light mt-8 gr-text-7">Flexible solution</p>
                </WidgetTextDiv>
              </div>
              <div className="feature-widget d-sm-flex my-4">
                <WidgetIconDiv>
                  <Icon src={iconDollarSign} alt="/" />
                </WidgetIconDiv>
                <WidgetTextDiv>
                  <p className="text-light mt-14 gr-text-7">
                    Highly affordable price
                  </p>
                </WidgetTextDiv>
              </div>
              <div className="feature-widget d-sm-flex my-4">
                <WidgetIconDiv>
                  <Icon className="" src={iconClipboard} alt="/" />
                </WidgetIconDiv>
                <WidgetTextDiv>
                  <p className="text-light mt-8 gr-text-7">
                    No upfront costs, year-long commitments, or added fees for
                    additional retailers
                  </p>
                </WidgetTextDiv>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Unit;
