import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import imgF1 from "../../assets/image/svg/l6-feature-icon1.svg";
import imgF2 from "../../assets/image/svg/l6-feature-icon2.svg";
import imgF3 from "../../assets/image/svg/l6-feature-icon3.svg";
import imgF4 from "../../assets/image/svg/l6-feature-icon4.svg";
import imgF5 from "../../assets/image/svg/l6-feature-icon5.svg";
import iconClock from "../../assets/image/clockgraph-icon-removebg-preview.png";
import iconComputer from "../../assets/image/computer-removebg-preview.png";
import iconDollarSign from "../../assets/image/dollar-sign-removebg-preview.png";
import iconClipboard from "../../assets/image/clipboard-icon-removebg-preview.png";
import imgCR from "../../assets/image/conference/png/content-3-round.png";
import imgLaptop from "../../assets/image/Section6_Laptop.png";

import section3Artwork from "../../assets/image/Section3_artwork.jpg";
import styled from "styled-components";
import { Link } from "gatsby";

const WidgetIconDiv = p => (
  <div
    {...p}
    className="widget-icon text-center gr-text-4 text-green mr-sm-9 mb-7 mb-lg-0"
  />
);
const WidgetTextDiv = p => (
  <div
    {...p}
    className="content text-center text-sm-left px-10 px-sm-0 pr-xl-6"
  />
);

const Artwork = styled.div`
  margin-top: -75px;
  margin-left: -150px;
  z-index: -1;
`;

const Solution = styled.p`
  margin-top: -15px;
`;

const Icon = styled.img`
  width: 150px;
`;

const Unit = () => {
  return (
    <>
      {/* <!-- Features Area --> */}
      <div className="feature-section py-24 bg-default-4">
        <Container>
          <Row className="justify-content-xs-center">
            <Col
              lg="10"
              className="bg-light-green rounded-xl text-dark px-14 py-12"
            >
              <Row>
                <Col lg="8" className="pt-8 pb-6">
                  <h1 className="gr-text-5 text-dark-green mb-6">
                    <div>Unlock Performance Marketing with Pear</div>
                  </h1>
                  <p className="text-dark">
                    With Pear, you can make data-driven decisions with
                    confidence by getting more accurate insight into campaign
                    performance based on session-level purchases - a benefit
                    completely unique to Pear.
                  </p>
                  <p className="text-dark">
                    We also have an extensive network supporting more than 1500
                    retailers. Pear is the only solution that allows consumers
                    to purchase at their favorite regional retailer, which can
                    significantly boost your brand's sales.
                  </p>
                  <Link to="/cpg" className="mt-6 btn btn-blue rounded-2">
                    Learn More
                  </Link>
                </Col>
                <Col lg="4"></Col>
              </Row>
            </Col>
            <Col lg="2" className="">
              <div
                className=" aos-init aos-animate"
                data-aos="fade-down-left"
                data-aos-delay="1000"
                data-aos-duration="1000"
                css={`
                  position: absolute;
                  top: 40px;
                  left: -90px;
                  z-index: 98;
                  width: 275px;
                `}
              >
                <img src={imgCR} alt="" className="w-100" />
              </div>
              <img
                src={imgLaptop}
                alt=""
                css={`
                  position: absolute;
                  width: 640px;
                  left: -400px;
                  top: 25px;
                  z-index: 99;
                `}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Unit;
