import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/pear-landing/Hero-Home-AM";
import StopWastingTimeAM from "../sections/pear-landing/Stop-Wasting-Time-AM";
import ExperienceUnrivaledConfidenceAM from "../sections/pear-landing/Experience-Unrivaled-Confidence-AM";
import WhatMakesPearDifferentAM from "../sections/pear-landing/What-Makes-Pear-Different-AM";
import UnlockPerformanceMarketingAM from "../sections/pear-landing/Unlock-Performance-Marketing-AM";
import StartCapturingSalesAM from "../sections/pear-landing/Start-Capturing-Sales-AM";

import Process from "../sections/pear-landing/Process";
import Features from "../sections/pear-landing/Features";
import Testimonials from "../sections/pear-landing/Testimonials";
import Newsletter from "../sections/landing2/Newsletter";
import ContactSmall from "../sections/pear-landing/ContactSmall";

const PearIndex = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "dark",
          align: "right",
          variant: "blue",
          button: "cta"
        }}
        footerConfig={{
          style: "style3" //style1, style2
        }}
      >
        <Hero />
        <StopWastingTimeAM className="bg-default" />
        <div className="alert-section my-8 py-18 bg-blue dark-mode-texts ">
          <div className="container">
            <div className="justify-content-center row">
              <div className="col-lg-11">
                <div className="alert-content d-flex flex-column flex-lg-row align-items-center text-center justify-content-lg-center">
                  {/* <span class="btn-badge rounded-pill bg-white gr-text-6 text-uppercase font-weight-bold text-primary py-1 px-6 mr-5 mb-6 mb-lg-0 d-inline-flex align-items-center">
                    New
                  </span> */}
                  <span className="alert-text gr-text-6 text-white">
                    Turn any media into measurable purchases.{" "}
                    <a
                      className="action-link text-white gr-text-underline"
                      href="#CPGContact"
                    >
                      Schedule a demo now!
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ExperienceUnrivaledConfidenceAM />
        <WhatMakesPearDifferentAM />
        <UnlockPerformanceMarketingAM />
        <StartCapturingSalesAM />

        <Newsletter className="bg-default-4" />
      </PageWrapper>
    </>
  );
};
export default PearIndex;
